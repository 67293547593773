<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="panel has-bg">
        <h3>{{ $t('edgeDistanceDIN.headlineEdgeContact') }}</h3>
        <div class="row">
          <div class="col-lg-6">
            <!-- Expositionklasse -->
            <b-form-group
              label-for="exposureClass"
            >
              <template slot="label">
                {{ $t('edgeDistanceDIN.exposureClass') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="bearingThickness"
                  v-model="exposureClass"
                  :options="exposureClassSelectOptions"
                  @change="changeExposureClass"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <!-- Durchmesser der Bügel -->
            <b-form-group label-for="diameterBow">
              <template slot="label">
                {{ $t('edgeDistanceDIN.diameterBow') }}
                <span class="text-muted">&phi;<sub>bü</sub></span>
              </template>
              <b-input-group append="mm">
                <FormInputDecimal
                  id="diameterBow"
                  v-model="diameterBow"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('edgeDistanceDIN.headlineIllustration') }}</h3>
        <img
          src="../../assets/img/abbildungen/randabstand-DIN4141.jpg"
          :alt="$t('edgeDistanceDIN.headlineIllustration')"
        />
      </div>
    </div>
    <div class="col-lg-6">
      <h3>{{ $t('bearings.headlineKeyFigures') }}</h3>
      <div class="table-responsive">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td>
                {{ $t('edgeDistanceDIN.minimum') }}
                <span class="text-muted">
                  C <sub>min</sub>
                </span>
              </td>
              <td class="text-right">
                {{ minimum }} mm
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('edgeDistanceDIN.nominalDimensions') }}
                <span class="text-muted">
                  C <sub>nom</sub>
                </span>
              </td>
              <td class="text-right">
                {{ nominalDimensions }} mm
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('edgeDistanceDIN.recommendedEdgeDistance') }}</strong>
              </td>
              <td class="text-right">
                <strong>
                  {{ recommendedEdgeDistance | numeralFormat('0.00') }} mm
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputDecimal from '../app/form/FormInputDecimal.vue';

export default {
  name: 'ToolsEccentricity',
  components: {
    FormInputDecimal,
  },
  data() {
    return {
      exposureClass: 'xc1',
      exposureClassSelectOptions: Object.values(this.$dataOptionsHelper.getExposureClassOptions()),
      exposureClassOptions: this.$dataOptionsHelper.getExposureClassOptions(),
      minimum: 10,
      diameterBow: 5,
    };
  },
  computed: {
    recommendedEdgeDistance() {
      return this.$outputHelper.convertToFloat(this.diameterBow, 2)
        + this.$outputHelper.convertToInteger(this.nominalDimensions);
    },
    nominalDimensions() {
      return this.$outputHelper.convertToInteger(this.minimum + 10);
    },
  },
  methods: {
    changeExposureClass(value) {
      if (typeof this.exposureClassOptions[value].min === 'number') {
        this.minimum = this.exposureClassOptions[value].min;
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
