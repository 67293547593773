<template>
  <ToolsFormEdgeDistanceDIN />
</template>

<script>
import ToolsFormEdgeDistanceDIN from '../../components/tools/ToolsFormEdgeDistanceDIN.vue';

export default {
  components: {
    ToolsFormEdgeDistanceDIN,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
